



































import { Component, Vue } from "vue-property-decorator";
import AccountDataContext from "@/dataContexts/AccountDataContext";
import { ValidationObserver } from "vee-validate";
import { InputComponent } from "keiryo";
import PasswordNotice from "@/components/global/PasswordNotice.vue";
import Router from "@/router/Router";
import Routes from "@/router/Routes";

@Component({
    components: {
        InputComponent,
        PasswordNotice
    }
})
export default class ResetPasswordView extends Vue {
    private accountDataContext: AccountDataContext = new AccountDataContext();

    private loading = false;

    private password = "";
    private confirmPassword = "";

    public async onSubmit() {
        const id = this.$route.query.id as string;
        const token = this.$route.query.token as string;
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        console.log(id);
        console.log(token);

        if (id && token && await observer.validate()) {
            this.loading = true;

            this.accountDataContext.resetPassword(id, token, this.password).then(x => {
                if (x.successfully) {
                    Router.navigate(Routes.Login);
                }
            }).finally(() => this.loading = false);
        }
    }
}
